import logo from "./logo.webp"

const  clientData = {
   client_entity: 39,
    attorney_firm: "allmandlaw.com",
    attorney_name: 'Reed Allmand',
    attorney_number: '+1-919-646-2654',
    attorney_email: 'rallmand@allmandlaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_allmand_law_firm+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#F1870C',
    siteLink: 'http://allmandlaw.com',
    logo
}

export default clientData